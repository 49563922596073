.container {
  max-width: 1000px !important;
}

@font-face {
  font-family: 'Avenir';
  src: url('./Assets/fonts/avenir_ff/AvenirLTStd-Black.otf') format('opentype');
  font-weight: 900;
  /* Black */
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./Assets/fonts/avenir_ff/AvenirLTStd-Book.otf') format('opentype');
  font-weight: 400;
 
  font-style: normal;
}

textarea:focus,
input:focus {
  outline: none;
}

.Form {
  margin-top: 3rem;
}

.Form input {
  padding: 4px;
}

.Visual2 {
  /* height: 400px; */
  width: 100%;
}
.Visual3 {
  width: 60%;
}

.Height {
  height: 100%;
}

:root {
  --primary: "Avenir";
  --secondary: "Fraunces", serif;
  --veryBig: 130px;
  --title: 65px;
  --title2: 60px;
  --text: 25px;
  --subTitle: 40px;
  --subTitle2: 33px;
  --regular: 16px;
  --grey: #e6e7e8;
  --dark: #403d42;
}

@media (max-width: 780px) {
  :root {
    --veryBig: 75px;
    --title: 45px;
    --title2: 30px;
    --text: 22px;
    --subTitle: 20px;
    --subTitle2: 17px;
    --regular: 13px;
  }

  .Height {
    height: auto;
  }

  .Visual2 {
    width: 100%;
  }
  .Visual3 {
    width: 100%;
  }

  label {
    font-size: 12px !important;
  }

  .Form input {
    padding: 0px;
  }
}

.Submit {
  transition: 0.2s ease-in-out;
  background-color: black;
  color: white;
  border: none;
}

.Submit:hover {
  transform: scale(1.02);
  background-color: white;
  color: black;
}

.swiper-pagination {
  width: auto !important;
  left: 80% !important;
  bottom: 40px !important;
}

strong {
  font-family: var(--secondary);
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}